.about_page {
    background-color:black;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  
  .container {
    text-align: center;
  }
  
  .about_page .container h2 {
    margin-top: 10%;
    margin-bottom:5%;
    color: white;
    font-size: 40px;
    font-family: Open Sans, Arial;
   }

  .about_page .container p {
      margin-left: 10%;
      margin-right: 10%;
    color: white;
    font-size: 20px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  /* DEMO PAGE */

  .demo_page {
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    text-align: center;
    overflow-y: scroll;
  }

  .demo_page h2 {
    margin-top: 3%;
    margin-bottom: 3%;
    color: white;
    font-size: 35px;
    font-family: Open Sans, Arial;
   }

   .demo_page h4 {
    margin-top: 3%;
    margin-bottom: 2%;
    color: white;
   }

  .demo_page p {
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 2%;
    text-align: left;
  color: white;
  font-size: 20px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }

  .demo_page ol {
    text-align: left;
    font-size: 20px;
    margin-left: 10%;
    margin-right: 10%;
  }

  .demo_page .video {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .demo_page .video_bottom {
    position: relative;
    overflow: hidden;
    width: 100%;
    margin-bottom: 5%;
  }

  .demo_page h3 {
    margin-top: 3%;
    margin-bottom: 3%;
    font-family: Open Sans, Arial;
    color: white;
  }

  .demo_page img {
    position: relative;
    overflow: hidden;
    width: 500px;
    background-color: rgb(248, 248, 248);
  }

  .demo_page .image_set {
    display: flex;
    justify-content: center;
    min-width: 500px;
  }

/* DEMO PAGE 2 */

.demo_page2 {
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: scroll;
}

.demo_page2 h2 {
  margin-top: 3%;
  color: white;
  font-size: 35px;
  font-family: Open Sans, Arial;
}

.demo_page2 p {
  margin-left: 10%;
  margin-right: 10%;
color: white;
font-size: 20px;
font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.demo_page2 ol {
  font-size: 20px;
  margin-left: 10%;
  margin-right: 10%;
}

.demo_page2 .video {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: auto;
  
  margin-bottom: 2%;
}

.demo_page2 h3 {
  margin-top: 2%;
  margin-bottom: 2%;
}

.demo_page2 img {
  position: relative;
  overflow: hidden;
  width: 500px;
  background-color: rgb(248, 248, 248);
}

.demo_page2 .image_set {
  display: flex;
  justify-content: center;
  min-width: 500px;
}
