.projects_page {
    background-color: black;
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
}

/* extra large layout */

.xlarge_dim .row {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 5%;
  margin-bottom: 2%;
}

.xlarge_dim button {
  font-size: 20px;
}

.xlarge_dim .row .neon_projects {
  display: inline;
  margin-right: 20%;
  margin-left: 20%;
  color: white;
  font-size: 70px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.xlarge_dim .row_2 {
  margin-top: 20%;
}

.xlarge_dim .row .first_img {
  margin-right: 35%;
}

/* large layout */

.large_dim .row {
  display: block;
  margin-top: 10%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.large_dim .row_2 {
  margin-top: 15%;
}

.large_dim .row .neon_projects {
  display: inline;
  margin-right: 13%;
  margin-left: 13%;
 color:white;
  font-size: 40px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.large_dim .row .first_img {
  margin-right: 20%;
}

/* medium layout */

.medium_dim .row {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    text-align: center;
}

.medium_dim .row .neon_projects {
    display: inline;
    margin-right: 10%;
    margin-left: 10%;
    color:white;
    font-size: 35px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.medium_dim .row_2 {
  margin-top: 20%;
}

.medium_dim .row .first_img {
    margin-right: 20%;
}

/* small layout */

.small_dim .row {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15%;
    text-align: center;
}

.small_dim .row .first_img {
    margin-right: 10%;
}

.small_dim .row h2 {
  color: white;
  font-size: 35px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}