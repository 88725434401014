.navbar {
    background-color: black
}

.btn-primary {
    background-color:black;
    border-color: white;
    color: white;
    font-family: Open Sans, Arial;
}

.btn-primary:visited,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
    background-color: rgb(255, 255, 255);
    color: black;
    border-color: white;
}

.home {
    margin-left: 2%;
}

.button_group {
    margin-right: 2%;
}