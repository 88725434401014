.home_page {
  background-color: black;
  position: fixed;
  width: 100%;
  height: 100%;
}

            .header-text {
                list-style: none;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translateX(-50%) translateY(-50%);
            }
            .header-text li {
                display: inline-block;
                margin-right: 50px;
                font-family: Open Sans, Arial;
                font-weight: 300;
                font-size: 4em;
                color: #fff;
                opacity: 1;
                transition: all 5s ease;
            }
            .header-text li:last-child {
                margin-right: 0;
            }